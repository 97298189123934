import { makeAutoObservable } from 'mobx'

import RootStore from '@app/stores/RootStore'
import Sidebar from '@app/stores/UiStore/Sidebar'
import Toaster from '@app/stores/UiStore/Toaster'
import DriftChat from './DriftChat'

export default class UiStore {
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false })

    this.rootStore = rootStore
    this.sidebar = new Sidebar(this)
    this.toaster = new Toaster(this)
    this.driftChat = new DriftChat()
  }

  rootStore: RootStore

  sidebar: Sidebar

  toaster: Toaster

  driftChat: DriftChat
}
