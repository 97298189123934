import { makeAutoObservable } from 'mobx'

import MenuBuilderStore from '@app/stores/MenuBuilderStore'

import FavoriteContainers from '@app/lib/api/FavoriteContainers'

import { IFavoriteContainer } from '@app/interfaces/shared'

import FavoriteContainer from './FavoriteContainer'
import { MODULIZED_ITEM_TYPES } from '@app/lib/constants'
import { FavoriteContainerParams } from '@app/lib/api/FavoriteContainers'

export default class FavoriteContainersStore {
  containers: FavoriteContainer[] = []
  menuBuilderStore: MenuBuilderStore

  constructor(menuBuilderStore: MenuBuilderStore) {
    this.menuBuilderStore = menuBuilderStore

    makeAutoObservable(this, { menuBuilderStore: false })
  }

  loadContainers = async () => {
    try {
      const { data } = await FavoriteContainers.list(this.locationId)

      this.setContainers(data.containers)
    } catch {
      // noop
    }
  }

  setContainers = (favoriteContainers: IFavoriteContainer[]) => {
    this.containers = favoriteContainers.map(
      (fc) => new FavoriteContainer(this, fc),
    )
  }

  getFavoriteContainerCountByItemType = (itemType: string) => {
    const modulizedItemType = MODULIZED_ITEM_TYPES[itemType]
    const containers = this.containersByItemType.get(modulizedItemType) || []

    return containers.length
  }

  createContainer = async (
    params: Partial<FavoriteContainerParams>,
    itemType: string,
  ) => {
    const modulizedItemType = MODULIZED_ITEM_TYPES[itemType]
    const newPosition = this.getFavoriteContainerCountByItemType(itemType)
    const newContainerAttributes = {
      ...params,
      position: newPosition,
      itemType: modulizedItemType,
    }

    this.setContainers([
      ...this.containers.map((fc) => fc.attributes),
      newContainerAttributes,
    ])

    const { data } = await FavoriteContainers.create(this.locationId, {
      ...newContainerAttributes,
      favorite: true,
    })

    const { container } = data
    this.containers.find((fc) => fc.id === null)?.set(container)
  }

  deleteContainer = async (id: number) => {
    const itemType = this.containers.find((fc) => fc.id === id)!.itemType!
    await FavoriteContainers.destroy(this.locationId, id)
    this.setContainers(this.containers.filter((fc) => fc.id !== id))
    const remainingContainers = this.containersByItemType.get(itemType) || []
    if (!remainingContainers.length) return
    this.updatePositions(remainingContainers)
  }

  updatePositions = async (newContainerPositions: FavoriteContainer[]) => {
    const newAttributes = this.containers.map((favoriteContainer) => {
      const newPosition = newContainerPositions.findIndex(
        (nc) => nc.id === favoriteContainer.id,
      )
      if (newPosition < 0) return favoriteContainer.attributes
      return { ...favoriteContainer.attributes, position: newPosition }
    })

    this.setContainers(newAttributes)

    FavoriteContainers.updatePositions(
      this.locationId,
      newContainerPositions.map((fc, idx) => ({ id: fc.id!, position: idx })),
    )
  }

  get containersByItemType(): Map<string, FavoriteContainer[]> {
    return this.containers
      .slice()
      .sort((a, b) => (a?.position || 0) - (b?.position || 0))
      .reduce((acc, fc) => {
        const containers = acc.get(fc.itemType) || []
        containers.push(fc)
        acc.set(fc.itemType, containers)
        return acc
      }, new Map())
  }

  get defaultContainersByItemType(): Map<string, FavoriteContainer[]> {
    return this.containers
      .slice()
      .sort((a, b) => (a?.position || 0) - (b?.position || 0))
      .reduce((acc, fc) => {
        if (fc.default) {
          const containers = acc.get(fc.itemType) || []
          containers.push(fc)
          acc.set(fc.itemType, containers)
        }
        return acc
      }, new Map())
  }

  get locationId(): number {
    return this.menuBuilderStore.rootStore.currentSubscription!.venue!.id
  }
}
