import { makeAutoObservable } from 'mobx'

import { CreditCard } from '@app/interfaces/shared'
import Countries from '@app/lib/api/billing/Countries'

import RootStore from '.'

export const FUSEBILL_URL =
  'https://payments.subscriptionplatform.com/api/paymentsv2'

interface CreateCardInput {
  CustomerID: number
  FirstName: string
  LastName: string
  CardNumber: string
  ExpirationMonth: string
  ExpirationYear: string
  PostalZip: string
  CVV: string
  address1: string
  address2?: string
  countryId: string
  stateId: string
  city: string
  recaptcha: string
  makeDefault: boolean
}

interface CreateCardResponse extends CreditCard {
  Errors?: {
    Value: string
  }[]
}

interface State {
  id: number
  name: string
}

interface Country {
  id: number
  name: string
  iso: string
  states: State[]
}

class Fusebill {
  rootStore: RootStore

  countries: Country[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  setCountries = (countries: Country[]): void => {
    this.countries = countries
  }

  loadCountries = async (): Promise<void> => {
    const { data } = await Countries.list()

    this.setCountries(data.countries)
  }

  async createCard(data: CreateCardInput): Promise<CreateCardResponse> {
    const res = await fetch(FUSEBILL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        PublicAPIKey: this.rootStore.config.fusebillPublicKey,
        ...data,
      }),
    })

    return res.json()
  }

  getCountry(iso: string): Country | undefined {
    return this.countries.find((country) => iso === country.iso)
  }

  getStates(iso: string): State[] {
    return this.getCountry(iso)?.states || []
  }
}

export default Fusebill
