import { IVenuePromo } from '@app/interfaces/shared'

import Base from './Base'

class VenuePromos extends Base {
  static async list(id: number): Promise<IVenuePromo[]> {
    const { data } = await this.get(`/locations/${id}/venue_promos`)

    return data
  }

  static async acknowledge(id: number) {
    return this.patch(`/venue_promos/${id}/acknowledge`)
  }
}

export default VenuePromos
