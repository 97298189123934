import { ModulizedItemType, Position } from '@app/interfaces/shared'
import Base from './Base'

export interface FavoriteContainerParams {
  id?: number | string | null
  container_size_id?: number
  default?: boolean
  favorite?: boolean
  itemType?: ModulizedItemType
  name?: string
  price?: string | number | null
}

class FavoriteContainers extends Base {
  static async list(locationId: number) {
    return this.get(`/locations/${locationId}/containers`)
  }

  static async create(locationId: number, params: FavoriteContainerParams) {
    return this.post(`/locations/${locationId}/containers`, {
      container: { ...params, favorite: true },
    })
  }

  static async destroy(locationId: number, containerId: number) {
    return this.delete(`/locations/${locationId}/containers/${containerId}`)
  }

  static async update(
    locationId: number,
    containerId: number | string,
    params: Partial<FavoriteContainerParams>,
  ) {
    return this.patch(`/locations/${locationId}/containers/${containerId}`, {
      container: params,
    })
  }

  static async updatePositions(locationId: number, positions: Position[]) {
    return this.patch(`/locations/${locationId}/container_positions`, {
      containers: positions,
    })
  }
}

export default FavoriteContainers
