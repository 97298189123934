import Base from './Base'

interface UpdateParams {
  role?: string
  goals?: string[]
  enabled?: boolean
}

class Members extends Base {
  static async update(id: number, params: UpdateParams) {
    return this.put(`/members/${id}`, params)
  }
  
  static async destroy(id: number) {
    return this.delete(`/members/${id}`)
  }
}

export default Members
