import { Position } from '@app/interfaces/shared'
import Base from './Base'

export interface ContainerDataParams {
  id?: number | string | null
  containerSizeId?: number | string
  calories?: number | string | null
  price?: number | string | null
  name?: string | null
  itemType?: string
  default?: boolean
}

export interface BulkCreateParams {
  containers: ContainerDataParams[]
  itemIds: number[]
  all: boolean
}

class Containers extends Base {
  static async create(itemId: number, params: ContainerDataParams) {
    return this.post(`/items/${itemId}/containers`, {
      container: params,
    })
  }

  static async update(id: number, params: ContainerDataParams) {
    return this.patch(`/containers/${id}`, {
      container: params,
    })
  }

  static async destroy(id: number) {
    return this.delete(`/containers/${id}`)
  }

  static async updatePositions(itemId: number, positions: Position[]) {
    return this.patch(`/items/${itemId}/container_positions`, {
      containers: positions,
    })
  }

  static async list(itemId: number) {
    return this.get(`/items/${itemId}/containers`)
  }

  static async containerSizeOptions() {
    return this.get('/container_size_options')
  }

  static async bulkCreate(sectionId: number, params: BulkCreateParams) {
    return this.post(`/sections/${sectionId}/items/containers`, params)
  }
}

export default Containers
