import { makeAutoObservable } from 'mobx'
import { IMember } from '@app/interfaces/shared'

export default class Member {
  id: number
  userId: number
  enabled: boolean
  membersCanManageMembers: boolean
  venuesCanAddAndRemoveBeers: boolean
  venuesCanAddAndRemoveSections: boolean
  venuesCanCreateEvent: boolean
  venuesCanCreateMenu: boolean
  venuesCanDeleteMenu: boolean
  venuesCanEditBeerInfo: boolean
  venuesCanListBusinessAnalytics: boolean
  venuesCanListDigitalBoards: boolean
  venuesCanListHours: boolean
  venuesCanListInvoices: boolean
  venuesCanListMenuAnnouncements: boolean
  venuesCanListNearbyAnalytics: boolean
  venuesCanListPrintThemes: boolean
  venuesCanListVenueAnalytics: boolean
  venuesCanListWebsiteThemes: boolean
  venuesCanCustomizePrintMenus: boolean
  venuesCanEditSite: boolean
  venuesCanShowLocationDetails: boolean
  venuesCanShowLocationInformation: boolean
  venuesCanUpdateMenuName: boolean
  venuesCanUpdateSectionName: boolean

  constructor(member: IMember) {
    this.set(member)

    makeAutoObservable(this)
  }

  set = (member: IMember): void => {
    Object.assign(this, member)
  }
}
