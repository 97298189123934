import { ReactElement } from 'react'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { Theme } from '@components'

type Imports = {
  [key: string]: () => Promise<{ default: React.ComponentType<any> }>
}

export default function componentLoader(imports: Imports = {}) {
  document.addEventListener('DOMContentLoaded', () => {
    const components = Array.prototype.slice.call(
      document.querySelectorAll('[data-component]'),
    )

    components.forEach((el) => {
      const data = el.dataset
      const load = imports[data.component]

      if (!load) {
        throw new Error(
          `Component (${data.component}) not found.
          Make sure you have resolved it correctly in the imports object`,
        )
      }

      load().then(({ default: Component }) => {
        const element: ReactElement = React.createElement(
          Component,
          JSON.parse(data.reactProps),
        )
        const root = createRoot(el)
        root.render(<Theme>{element}</Theme>)
      })
    })
  })
}
