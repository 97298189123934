import { makeAutoObservable } from 'mobx'

import FavoriteContainers from '@app/lib/api/FavoriteContainers'
import { IFavoriteContainer, ModulizedItemType } from '@app/interfaces/shared'
import FavoriteContainersStore from '.'

export default class FavoriteContainer {
  favoriteContainersStore: FavoriteContainersStore

  id?: number | null = null

  name?: string

  updatedAt?: string = ''

  createdAt?: string = ''

  locationId?: number | null = null

  default?: boolean = false

  itemType?: ModulizedItemType

  price?: number = 0

  containerSizeId?: string | number = ''

  position?: number | null = null

  active?: boolean = false

  favorite?: boolean = true

  constructor(
    favoriteContainersStore: FavoriteContainersStore,
    favoriteContainer: IFavoriteContainer,
  ) {
    this.favoriteContainersStore = favoriteContainersStore

    this.set(favoriteContainer)

    makeAutoObservable(this, { favoriteContainersStore: false })
  }

  toggleDefault = async () => {
    this.default = !this.default

    this.save()
  }

  set = (favoriteContainer: Partial<IFavoriteContainer>) => {
    Object.assign(this, favoriteContainer)
  }

  save = async () => {
    const { data } = await FavoriteContainers.update(
      this.locationId!,
      this.id!,
      this.attributes,
    )

    return data
  }

  get attributes(): Partial<IFavoriteContainer> {
    return {
      id: this.id,
      name: this.name,
      updatedAt: this.updatedAt,
      default: this.default,
      itemType: this.itemType,
      price: this.price,
      containerSizeId: this.containerSizeId,
      position: this.position,
    }
  }
}
