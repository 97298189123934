import { makeAutoObservable } from 'mobx'
import { arrayMove } from '@dnd-kit/sortable'

import Menus, { UpdateParams } from '@app/lib/api/Menus'
import Sections, { Params as SectionParams } from '@app/lib/api/Sections'
import { IMenu, ISection } from '@app/interfaces/shared'
import MenuBuilderStore from '@app/stores/MenuBuilderStore'
import Section from './Section'
import { AxiosError } from 'axios'

export default class Menu {
  menuBuilderStore: MenuBuilderStore

  locationId: number

  id: number

  name: string

  unpublished: boolean

  description?: string

  updatedAt: string

  showPriceOnUntappd: boolean

  itemsCount: number

  genericSections: Section[] = []

  currentSection: Section | null = null

  showEditDrawer = false

  showDeleteDialog = false

  showAddSection = false

  isLoading = false

  constructor(menuBuilderStore: MenuBuilderStore, menu: IMenu) {
    this.menuBuilderStore = menuBuilderStore

    this.set(menu)

    makeAutoObservable(this, { menuBuilderStore: false })
  }

  set = (menu: IMenu) => {
    Object.assign(this, menu)
  }

  loadSections = async () => {
    if (this.genericSections.length > 0) return

    this.setIsLoading(true)
    try {
      const { data } = await Sections.list(this.id)

      this.setGenericSections(
        data.sections.map((section: ISection) => new Section(this, section)),
      )
    } catch (e) {
      this.menuBuilderStore.rootStore.handleAxiosError(e as AxiosError)
    } finally {
      this.setIsLoading(false)
    }
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setGenericSections = (sections: Section[]) => {
    this.genericSections = sections
  }

  setCurrentSection = (section: Section | null) => {
    if (section) section.reset()
    this.currentSection = section
  }

  setShowEditDrawer = (showEditDrawer: boolean) => {
    this.showEditDrawer = showEditDrawer
  }

  setShowDeleteDialog = (showDeleteDialog: boolean) => {
    this.showDeleteDialog = showDeleteDialog
  }

  setShowAddSection = (showAddSection: boolean) => {
    this.showAddSection = showAddSection
  }

  update = async (params: UpdateParams) => {
    const { data } = await Menus.update(this.id, params)
    this.set(data.menu)
  }

  destroy = async () => {
    return this.menuBuilderStore.deleteMenu()
  }

  createSection = async (params: SectionParams) => {
    const { data } = await Sections.create(this.id, params)

    this.addSection(data.section)
  }

  addSection = async (section: ISection) => {
    this.genericSections.push(new Section(this, section))
  }

  updateSectionPosition = async (oldIndex: number, newIndex: number) => {
    const reorderedSections = arrayMove(this.sections, oldIndex, newIndex)
    this.setGenericSections([...reorderedSections, this.onDeckSection!])

    const positions = reorderedSections.map((item, index) => ({
      id: item.id,
      position: index,
    }))

    Sections.updatePositions(this.id, positions)
  }

  deleteSection = (id: number) => {
    Sections.destroy(id)

    const sections = this.genericSections.filter((section) => section.id !== id)
    this.setGenericSections(sections)
  }

  get totalItemsCount() {
    return this.genericSections.reduce(
      (sum, section) => sum + section.itemsCount,
      0,
    )
  }

  get sections(): Section[] {
    return this.genericSections.filter(
      (section) => section.type !== 'OnDeckSection',
    )
  }

  get onDeckSection(): Section | undefined {
    const onDeckSection = this.genericSections.find(
      (section) => section.type === 'OnDeckSection',
    )

    return onDeckSection
  }

  get attributes(): Partial<IMenu> {
    return {
      id: this.id,
      name: this.name,
      unpublished: this.unpublished,
      description: this.description,
      updatedAt: this.updatedAt,
      showPriceOnUntappd: this.showPriceOnUntappd,
    }
  }
}
