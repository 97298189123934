import Cookies from 'universal-cookie'
import axios from 'axios'

const cookies = new Cookies()

const http = (url: string, options = {}) =>
  axios({
    url,
    withCredentials: true,
    headers: {
      'x-csrf-token': cookies.get('X-CSRF-TOKEN'),
      'Content-Type': 'application/json',
    },
    ...options,
  })

export default http
