const TIMEOUT = 500

export default class DriftChat {
  timeout: NodeJS.Timeout | undefined

  open = (): void => {
    const openChat = (): void => window.drift.api.openChat()

    if (window.drift.apiReady) {
      this.timeout = setTimeout(openChat, TIMEOUT)
    } else {
      window.drift.on('ready', (): void => {
        this.timeout = setTimeout(openChat, TIMEOUT)
      })
    }
  }

  close = (): void => {
    window.drift.api.widget.hide()
    if (this.timeout) clearTimeout(this.timeout)
  }
}
