import { makeAutoObservable } from 'mobx'

import LocalBadges from '@app/lib/api/LocalBadges'

import RootStore from '@app/stores/RootStore'
import { LocalBadge } from '@app/interfaces/insights'

export default class LocalBadgeStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore
  localBadges: LocalBadge[]

  loadLocalBadges = async () => {
    const localBadges = await LocalBadges.list({
      venueId: this.rootStore.currentSubscription?.venue.id,
    })

    this.setLocalBadges(localBadges)
  }

  setLocalBadges = (localBadges: LocalBadge[]) => {
    this.localBadges = localBadges
  }
}
