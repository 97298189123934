import qs from 'qs'

import { Position, IContainer } from '@app/interfaces/shared'
import Base from './Base'

export interface ListParams {
  startIndex?: number
  stopIndex?: number
  sortBy?: string
  filter?: string
}

export interface ItemParams {
  name: string
  calories?: string
  description: string
  type: string
  customLabelImage: File | null
  producer?: string
  category?: string | null
  vintage?: string | null
  location?: string | null
  characteristics?: string | null
  abv?: number | null
  ibu?: number | null
  style?: number
  breweryId?: string
  bypassDupe?: boolean
  containers?: IContainer[]
}

export interface UpdateParams {
  name?: string
  brewery?: string | null
  producer?: string | null
  style?: string | null
  category?: string | null
  vintage?: string | null
  characteristics?: string | null
  location?: string | null
  abv?: number | string | null
  ibu?: number | string | null
  calories?: string | null
  description?: string
  tapNumber?: string
  nitro?: boolean
  cask?: boolean
  local?: boolean
}

interface WineSearcherSearchParams {
  locationId: number
  type: string
  query: string
}

export interface WineSearcherParams {
  wineSearcherId: string
  vintage: string
  type: string
  unique?: boolean
}

export interface UntappdParams {
  untappdId: number
  unique?: boolean
}

export interface BulkMoveParams {
  originalSectionId: number
  itemIds: number[]
  all: boolean
}

export interface BulkDestroyParams {
  itemIds: number[]
  all: boolean
}

class Items extends Base {
  static async list(sectionId: number, params: ListParams) {
    return this.get(
      `/sections/${sectionId}/items?${qs.stringify({
        start_index: params.startIndex,
        stop_index: params.stopIndex,
        filter: params.filter,
        sort_by: params.sortBy,
        full: true,
      })}`,
    )
  }

  static async create(sectionId: number, params: ItemParams) {
    return this.post(`/sections/${sectionId}/items`, {
      ...params,
      full: true,
    })
  }

  static async updatePositions(sectionId: number, positions: Position[]) {
    return this.patch(`/sections/${sectionId}/items/positions`, {
      items: positions,
    })
  }

  static async bulkMove(sectionId: number, params: BulkMoveParams) {
    return this.patch(`/sections/${sectionId}/items/move`, params)
  }

  static async bulkDestroy(sectionId: number, params: BulkDestroyParams) {
    return this.delete(`/sections/${sectionId}/items`, {
      data: params,
    })
  }

  static async searchWineSearcher({
    locationId,
    type,
    query,
  }: WineSearcherSearchParams) {
    return this.get(
      `/items/search/winesearcher?type=${encodeURIComponent(
        type,
      )}&location_id=${locationId}&query=${encodeURIComponent(query)}`,
    )
  }

  static async createWineSearcherItem(
    sectionId: number,
    params: WineSearcherParams,
  ) {
    return this.post(`/sections/${sectionId}/items/wine_searcher`, {
      ...params,
      full: true,
      default_containers: true,
    })
  }

  static async createUntappdItem(sectionId: number, params: UntappdParams) {
    return this.post(`/sections/${sectionId}/items`, {
      ...params,
      full: true,
      default_containers: true,
    })
  }

  static async createUntappdItems(
    sectionId: number,
    params: { untappdId: number[] },
  ) {
    return this.post(`/sections/${sectionId}/items`, {
      ...params,
      full: true,
      default_containers: true,
    })
  }

  static async destroy(id: number) {
    return this.delete(`/items/${id}`)
  }

  static async update(id: number, item: UpdateParams) {
    return this.put(`/items/${id}`, {
      item,
    })
  }

  static async uploadImage(id: number, image: File | null) {
    const data = new FormData()
    data.append('item[custom_label_image]', image!)

    return this.patch(`/items/${id}`, data, {
      transformRequest: (data: object) => data,
    })
  }

  static async move(id: number, params: { sectionId: number }) {
    return this.patch(`/items/${id}/move`, params)
  }

  static async replace(id: number, replaceItemId: number) {
    return this.patch(`/items/${id}/replace`, {
      replaceItemId,
    })
  }
}

export default Items
