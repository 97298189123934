import { ReactNode } from 'react'

import { Box } from '@mui/material'

export enum PremiumIcons {
  SEARCH_ITEM = 'premium_search_item',
  GENERIC_ITEM_LIMIT = 'premium_generic_item_limit',
  USER_LIMIT = 'premium_user_limit',
  API_ACCESS = 'premium_api_access',
}

export interface PremiumFeatureProps {
  icon: PremiumIcons
  description: ReactNode
}

const PremiumFeature = ({ icon, description }: PremiumFeatureProps) => (
  <Box display="flex" alignItems="center" gap={6} my={4} px={5}>
    <img
      width={100}
      src={`https://assets.untappd.com/business/images/${icon}.svg`}
      alt={icon}
    />
    <Box>{description}</Box>
  </Box>
)

export default PremiumFeature
