import { makeAutoObservable, runInAction } from 'mobx'
import algoliasearch, { SearchClient } from 'algoliasearch'
import algoliaAnalytics from 'search-insights'

import { Environment, SelectOption, IConfig } from '@app/interfaces/shared'

import RootStore from '.'

export default class Config {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  algoliaAppId = ''

  algoliaSearchKey = ''

  algoliaMenuIndex = ''

  fusebillPublicKey = ''

  statsigApiKey = ''

  rollbarAccessToken = ''

  railsEnvironment = Environment.DEVELOPMENT

  timezones: SelectOption<string>[] = []

  currencies: SelectOption<string>[] = []

  businessCategories: SelectOption<string>[] = []

  defaultMaxGenericItemsCount = 20

  defaultMaxUserCount = 5

  algoliaClient: SearchClient | undefined

  googleApiKey = ''

  paymentTypes: SelectOption<string>[] = []

  setConfig = (config: IConfig): void => {
    runInAction(() => {
      this.algoliaAppId = config.algoliaAppId
      this.algoliaSearchKey = config.algoliaSearchKey
      this.algoliaMenuIndex = config.algoliaMenuIndex
      this.fusebillPublicKey = config.fusebillPublicKey
      this.railsEnvironment = config.railsEnvironment as Environment
      this.timezones = config.timezones
      this.currencies = config.currencies
      this.businessCategories = config.businessCategories
      this.defaultMaxGenericItemsCount = config.defaultMaxGenericItemsCount
      this.defaultMaxUserCount = config.defaultMaxUserCount
      this.statsigApiKey = config.statsigApiKey
      this.rollbarAccessToken = config.rollbarAccessToken
      this.googleApiKey = config.googleApiKey
      this.paymentTypes = config.paymentTypes
    })
  }

  initializeAlgoliaClient = () => {
    runInAction(() => {
      this.algoliaClient = algoliasearch(
        this.algoliaAppId,
        this.algoliaSearchKey,
      )
    })

    algoliaAnalytics('init', {
      appId: this.algoliaAppId,
      apiKey: this.algoliaSearchKey,
    })
  }

  get isProduction(): boolean {
    return this.railsEnvironment === Environment.PRODUCTION
  }

  get isNonProduction(): boolean {
    return !this.isProduction
  }
}
