import Cookies from 'universal-cookie'
import axios from 'axios'
import humps from 'humps'

const cookies = new Cookies()
const HTTP = axios.create({
  baseURL: '/api/v1',
  withCredentials: true,
  headers: {
    'x-csrf-token': cookies.get('X-CSRF-TOKEN'),
    'Content-Type': 'application/json',
    ...(cookies.get('basic_auth')
      ? {
          'User-Agent': 'UTFB-Mobile',
          Authorization: cookies.get('basic_auth').replace('Basic+', 'Basic '),
        }
      : {}),
  },
  transformRequest: (data) => JSON.stringify(humps.decamelizeKeys(data)),
  transformResponse: (data) => {
    try {
      return humps.camelizeKeys(JSON.parse(data))
    } catch (e) {
      return data
    }
  },
})

class Base {
  static get(url: string, config = {}) {
    return HTTP.get(url, config)
  }

  static put(url: string, data = {}, config = {}) {
    return HTTP.put(url, data, config)
  }

  static patch(url: string, data = {}, config = {}) {
    return HTTP.patch(url, data, config)
  }

  static post(url: string, data = {}, config = {}) {
    return HTTP.post(url, data, config)
  }

  static delete(url: string, data = {}) {
    return HTTP.delete(url, data)
  }
}

export default Base
