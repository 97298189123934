import { makeAutoObservable, runInAction } from 'mobx'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import { arrayMove } from '@dnd-kit/sortable'

import RootStore from '@app/stores/RootStore'
import { IContainer, IItem, ISection } from '@app/interfaces/shared'
import Sections, { Params } from '@app/lib/api/Sections'
import { UntappdParams } from '@app/lib/api/Items'
import {
  BEER_ITEM_TYPE,
  ITEM_PAGE_SIZE,
  MERCHANDISE_ITEM_TYPE,
} from '@app/lib/constants'
import Items, {
  ItemParams,
  WineSearcherParams,
  ListParams,
} from '@app/lib/api/Items'
import Locations from '@app/lib/api/Locations'
import Containers from '@app/lib/api/Containers'

import Menu from './Menu'
import Item from './Item'
import Container from './Container'
import MoveItems from './MoveItems'
import Subscription from '../models/Subscription'

export default class Section implements ISection {
  menu: Menu

  menuId: number

  id: number

  name: string

  description: string

  itemsCount: number

  type: 'Section' | 'OnDeckSection'

  updatedAt: string

  public: boolean

  items: Item[] = []

  isLoading = false

  query = ''

  newItemType = ''

  isAddItemOpen = false

  isAddingItem = false

  currentItemId: number | null = null

  selectedItems: Set<number> = new Set()

  duplicateBeers: IItem[] = []

  duplicateItem: IItem | null = null

  topBeers: IItem[] = []

  filter = ''

  allSelected = false

  showEditDrawer = false

  showDeleteDialog = false

  showMoveItemsDialog = false

  showAddContainersDialog = false

  showDeleteItemsDialog = false

  showImportTopBeersDialog = false

  moveItems = new MoveItems(this)

  constructor(menu: Menu, section: ISection) {
    this.menu = menu
    this.set(section)

    makeAutoObservable(this, { menu: false })
  }

  set = (section: Partial<ISection>) => {
    Object.assign(this, {
      ...section,
      name: section.name || 'Untitled Section',
    })
  }

  update = async (params: Params) => {
    const { data } = await Sections.update(this.id, params)

    this.set(data.section)
  }

  loadItems = async ({
    startIndex = 0,
    stopIndex = ITEM_PAGE_SIZE - 1,
    sortBy = '',
  }: ListParams = {}) => {
    if (startIndex === stopIndex) return
    if (startIndex === 0) this.setItems([])

    this.setLoading(true)
    try {
      const { data } = await Items.list(this.id, {
        startIndex,
        stopIndex,
        sortBy,
        filter: this.filter,
      })

      this.setItems([
        ...this.items,
        ...data.items.map((item: IItem) => new Item(this, item)),
      ])

      if (this.allSelected) this.selectAllItems()
    } catch {
      // noop
    } finally {
      this.setLoading(false)
    }
  }

  debounceLoadItems = debounce(this.loadItems, 300)

  loadTopBeers = async () => {
    if (this.topBeers.length > 0) return Promise.resolve()

    const { data } = await Locations.topBeers(this.subscription.venue.id)

    this.setTopBeers(data.items)
  }

  setItemsCount = (count: number) => {
    this.itemsCount = count
  }

  setItems = (items: Item[]) => {
    this.items = items
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading
  }

  setQuery = (query: string) => {
    this.query = query
  }

  setNewItemType = (newItemType: string) => {
    this.newItemType = newItemType
  }

  setIsAddItemOpen = (isAddItemOpen: boolean) => {
    this.isAddItemOpen = isAddItemOpen
  }

  setIsAddingItem = (isAddingItem: boolean) => {
    this.isAddingItem = isAddingItem
  }

  setCurrentItem = (id: number | null) => {
    this.currentItemId = id
  }

  setDuplicateBeers = (items: IItem[]) => {
    this.duplicateBeers = items
  }

  setDuplicateItem = (item: IItem | null) => {
    this.duplicateItem = item
  }

  setTopBeers = (items: IItem[]) => {
    this.topBeers = items
  }

  setShowEditDrawer = (showEditDrawer: boolean) => {
    this.showEditDrawer = showEditDrawer
  }

  setShowDeleteDialog = (showDeleteDialog: boolean) => {
    this.showDeleteDialog = showDeleteDialog
  }

  setShowMoveItemsDialog = (showMoveItemsDialog: boolean) => {
    this.showMoveItemsDialog = showMoveItemsDialog
  }

  setShowAddContainersDialog = (showAddContainersDialog: boolean) => {
    this.showAddContainersDialog = showAddContainersDialog
  }

  setShowDeleteItemsDialog = (showDeleteItemsDialog: boolean) => {
    this.showDeleteItemsDialog = showDeleteItemsDialog
  }

  setShowImportTopBeersDialog = (showImportTopBeersDialog: boolean) => {
    this.showImportTopBeersDialog = showImportTopBeersDialog
  }

  selectItem = (id: number) => {
    this.selectedItems.add(id)
  }

  unselectItem = (id: number) => {
    this.selectedItems.delete(id)
    this.setAllSelected(false)
  }

  selectAllItems = () => {
    this.selectedItems = new Set(this.items.map((item) => item.id))
  }

  unselectAllItems = () => {
    this.selectedItems.clear()
    this.setAllSelected(false)
  }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  setAllSelected = (allSelected: boolean) => {
    this.allSelected = allSelected
  }

  sort = async (sortBy: string) => {
    this.loadItems({ sortBy })
  }

  createItem = async (params: ItemParams) => {
    this.setIsAddingItem(true)
    const { data } = await Items.create(this.id, params)

    if (!isEmpty(data.duplicates)) {
      this.setDuplicateBeers(data.duplicates)
      this.setIsAddingItem(false)
      return
    }

    if (params.customLabelImage) {
      const { data: imageUploadData } = await Items.uploadImage(
        data.item.id,
        params.customLabelImage,
      )

      this.addItem({ ...data.item, ...imageUploadData.item })
    } else {
      this.addItem(data.item)
    }
    if (data.type !== BEER_ITEM_TYPE) this.updateGenericItemsCount(1)
    this.setIsAddingItem(false)
    this.closeAddItemDrawer()
  }

  createWineSearcherItem = async (params: WineSearcherParams) => {
    this.setIsAddingItem(true)
    const { data } = await Items.createWineSearcherItem(this.id, params)

    if (data.item.duplicate) {
      this.setDuplicateItem(data.item)
    } else {
      this.addItem(data.item)
      this.updateGenericItemsCount(1)
    }

    this.setIsAddingItem(false)
  }

  createUntappdItem = async (params: UntappdParams) => {
    this.setIsAddingItem(true)
    const { data } = await Items.createUntappdItem(this.id, params)

    if (data.item.duplicate) this.setDuplicateItem(data.item)
    else this.addItem(data.item)

    this.setIsAddingItem(false)
  }

  createUntappdItems = async (untappdId: number[]) => {
    const { data } = await Items.createUntappdItems(this.id, {
      untappdId,
    })

    const ids = new Set(untappdId)
    const items = (data.items as IItem[])
      .filter((item) => ids.has(item.untappdId!))
      .map((item: IItem) => new Item(this, item))

    this.items = [...items, ...this.items]
    this.updateSectionItemsCount(data.items.length)
  }

  addItem = (item: IItem) => {
    this.items.unshift(new Item(this, item))
    this.updateSectionItemsCount(1)
    this.setDuplicateItem(null)
  }

  updateItemPosition = (oldIndex: number, newIndex: number) => {
    const reorderedItems = arrayMove(this.items, oldIndex, newIndex)
    reorderedItems.forEach((item, index) => {
      item.setPosition(index)
    })
    this.setItems(reorderedItems)

    const positions = reorderedItems.map((item) => ({
      id: item.id,
      position: item.position,
    }))

    Items.updatePositions(this.id, positions)
  }

  deleteItem = async (id: number) => {
    const index = this.items.findIndex((item) => item.id === id)
    const item = this.items[index]
    this.items = this.items.filter((item) => item.id !== id)

    this.updateSectionItemsCount(-1)
    if (item!.type !== BEER_ITEM_TYPE) this.updateGenericItemsCount(-1)

    Items.destroy(id)
  }

  updateSectionItemsCount = (amount: number) => {
    this.itemsCount += amount
  }

  updateGenericItemsCount = (amount: number) => {
    const venue = this.subscription.venue
    venue.set({ genericItemsCount: venue.genericItemsCount + amount })
  }

  closeAddItemDrawer = () => {
    this.setIsAddItemOpen(false)
    this.setNewItemType('')
    this.setDuplicateBeers([])
    this.setQuery('')
  }

  advanceCurrentItem = (advance = 1) => {
    const index = this.items.findIndex((item) => item.id === this.currentItemId)
    const newIndex = index + advance
    const item = this.items[newIndex]
    this.setCurrentItem(item.id)

    if (
      newIndex === this.items.length - 1 &&
      this.items.length < this.itemsCount &&
      !this.filter
    ) {
      this.loadItems({
        startIndex: this.items.length,
        stopIndex: this.items.length + ITEM_PAGE_SIZE - 1,
      })
    }
  }

  deleteItems = async () => {
    await Items.bulkDestroy(this.id, {
      itemIds: [...this.selectedItems],
      all: this.allSelected,
    })

    const items = this.allSelected
      ? []
      : this.items.filter((item) => !this.selectedItems.has(item.id))

    runInAction(() => {
      this.itemsCount -= this.selectedCount
    })
    this.setItems(items)
    this.unselectAllItems()

    if (this.items.length === 0 && this.itemsCount > 0) {
      this.loadItems()
    }
  }

  createContainers = async (containers: IContainer[]) => {
    const { data } = await Containers.bulkCreate(this.id, {
      containers,
      itemIds: [...this.selectedItems],
      all: this.allSelected,
    })

    const containersByItem: Map<number, IContainer[]> = data.containers.reduce(
      (acc: Map<number, IContainer[]>, container: IContainer) => {
        const { itemId } = container
        if (!itemId) return acc
        const containers = acc.get(itemId)
        if (!containers) acc.set(itemId, [container])
        else containers.push(container)
        return acc
      },
      new Map(),
    )

    this.items.forEach((item) => {
      if (!containersByItem.has(item.id)) return

      item.setContainers([
        ...item.containers,
        ...containersByItem
          .get(item.id)!
          .map((container) => new Container(item, container)),
      ])
    })
  }

  reset = () => {
    this.setItems([])
    this.setQuery('')
    this.setNewItemType('')
    this.setIsAddItemOpen(false)
    this.setIsAddingItem(false)
    this.setCurrentItem(null)
    this.setDuplicateBeers([])
    this.setDuplicateItem(null)
    this.setTopBeers([])
    this.setShowEditDrawer(false)
    this.setShowDeleteDialog(false)
    this.setShowMoveItemsDialog(false)
    this.setFilter('')
    this.unselectAllItems()
  }

  get isOnDeck() {
    return this.type === 'OnDeckSection'
  }

  get attributes(): Partial<ISection> {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      itemsCount: this.itemsCount,
      type: this.type,
      updatedAt: this.updatedAt,
      public: this.public,
    }
  }

  get rootStore(): RootStore {
    return this.menu.menuBuilderStore.rootStore
  }

  get currentItem(): Item | undefined {
    const item = this.items.find((item) => this.currentItemId === item.id)
    return item
  }

  get containsBeer(): boolean {
    return this.items.some((item) => item.type === BEER_ITEM_TYPE)
  }

  get containsNonBeer(): boolean {
    return this.items.some((item) => item.type !== BEER_ITEM_TYPE)
  }

  get containsMerchandise(): boolean {
    return this.items.some((item) => item.type === MERCHANDISE_ITEM_TYPE)
  }

  get isCurrentItemFirst(): boolean {
    return this.currentItem === this.items[0]
  }

  get isCurrentItemLast(): boolean {
    return this.currentItem === this.items[this.items.length - 1]
  }

  get selectedCount(): number {
    if (this.allSelected) return this.itemsCount

    return this.selectedItems.size
  }

  get subscription(): Subscription {
    return this.rootStore.currentSubscription!
  }
}
