import { Link as MuiLink, Box } from '@mui/material'
import { ChevronLeft } from 'react-feather'
import styled from 'styled-components'
import { yellow50, gray60, useDown } from '@theme'

const Highlight = styled(Box)`
  border-bottom: 4px solid ${yellow50};
  padding-bottom: 12px;
  margin-bottom: -15px;
  width: 135%;
  margin-left: -12px;
`

const LaunchpadLinkContainer = styled(Box).attrs({
  display: 'flex',
  alignItems: 'center',
  ml: -1,
  px: 5,
})`
  && {
    color: ${gray60};
    &:hover {
      color: ${gray60};
    }
  }
`

const Bold = styled(Box).attrs({
  fontWeight: 'fontWeightBold',
})``

const Link = styled(MuiLink).attrs({
  color: 'inherit',
})``

interface HeaderLeftProps {
  singleVenueLink: boolean
}

const HeaderLeft = ({ singleVenueLink }: HeaderLeftProps) => {
  const downSm = useDown('sm')
  const { location } = window
  const allVenuesMenusUrl = '/app/menus'
  const homeLink = '/'

  const isLaunchpad = location.pathname === homeLink
  const isAllMenus = location.pathname === allVenuesMenusUrl

  if (singleVenueLink) {
    return (
      <Link href={homeLink}>
        <LaunchpadLinkContainer>
          <ChevronLeft />
          <Box>
            {!downSm && <span>Back to </span>}
            <Bold component="span">Launchpad</Bold>
          </Box>
        </LaunchpadLinkContainer>
      </Link>
    )
  }

  return (
    <>
      <Link href={homeLink}>
        <Box mx={downSm ? 4 : 6}>
          <Bold>Launchpad</Bold>
          {isLaunchpad && <Highlight />}
        </Box>
      </Link>
      <Link href={allVenuesMenusUrl}>
        <Bold>All Menus</Bold>
        {isAllMenus && <Highlight />}
      </Link>
    </>
  )
}

export default HeaderLeft
