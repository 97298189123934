import styled from 'styled-components'
import { Box } from '@mui/material'
import { X } from 'react-feather'

import { red10, red50 } from '@theme'

const REASONS = [
  'Search and add wine and spirits to your menus from our database, just like beer!',
  'Add unlimited non-beer items like food or merchandise to your menu',
  'Unlimited users',
  'API access to take your menus to a new level',
]

const Reason = styled(Box).attrs({
  p: 4,
  mb: 3,
})`
  align-items: center;
  display: flex;
  background-color: ${red10};
  border-radius: 4px;
  font-weight: 600;
  line-height: 18px;
`

const ReasonIcon = styled(X).attrs({
  color: red50,
  size: 18,
})`
  margin-right: 16px;
  min-width: 18px;
`

const LosePremiumFeatures = (): JSX.Element => (
  <>
    {REASONS.map((reason) => (
      <Reason key={reason}>
        <ReasonIcon />
        {reason}
      </Reason>
    ))}
  </>
)

export default LosePremiumFeatures
