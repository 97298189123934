import { useState } from 'react'
import { Box, Button, DialogContent, DialogActions } from '@mui/material'

import styled from 'styled-components'
import { white, gray60 } from '@theme'
import { Info } from 'react-feather'
import moment from 'moment'

import http from '@utils/http'

import Dialog from '../DialogDarkBackground'

const GotItButton = styled(Button).attrs({
  fullWidth: true,
})`
  color: ${gray60} !important;
  background-color: ${white} !important;
`

export interface PremiumIntroductionDialogProps {
  subscriptionId: number
  complimentaryPremiumEndAt: string
}

const PremiumIntroductionDialog = ({
  subscriptionId,
  complimentaryPremiumEndAt,
}: PremiumIntroductionDialogProps) => {
  const [open, setOpen] = useState(true)

  const dismiss = () => {
    setOpen(false)
    try {
      http('/api/v1/dismiss_notice', {
        method: 'patch',
        data: {
          notice: `premium_access_introduction:${subscriptionId}`,
        },
      })
    } catch {
      // noop
    }
  }

  return (
    <Dialog open={open}>
      <DialogContent style={{ overflowX: 'hidden' }} sx={{ pb: 6 }}>
        <Box
          component="img"
          my={8}
          mx="auto"
          src="https://assets.untappd.com/business/images/utfb_premium_logo.svg"
          alt="Untappd for Business Premium"
        />

        <Box fontSize={24} textAlign="center">
          Introducing{' '}
          <Box component="span" fontWeight="fontWeightBold">
            Untappd for Business Premium!
          </Box>{' '}
          It is the biggest upgrade we’ve ever made.
        </Box>

        <Box
          borderRadius={1}
          display="flex"
          alignItems="center"
          bgcolor="#FFFFFF1A"
          gap={4}
          p={5}
          my={6}
        >
          <Info size={24} style={{ minWidth: 24 }} />
          <Box>
            <Box component="span" fontWeight="fontWeightBold">
              The best part? You will be able to use the premium features until{' '}
              {moment(complimentaryPremiumEndAt).format('MM/DD/YYYY')}!
            </Box>{' '}
            Don&apos;t worry, we won&apos;t auto-bill you. There will be an
            option to stay on the premium plan when the free experience ends.
          </Box>
        </Box>
        <Box fontWeight="fontWeightBold" fontSize={18} width="100%" mb={4}>
          Check out the Untappd for Business premium features:
        </Box>
        <Box display="flex" alignItems="start" flexDirection="column">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/9hr6VwbjjoQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
            style={{ borderRadius: '4px' }}
          ></iframe>
        </Box>
      </DialogContent>
      <DialogActions sx={{ width: '100%', boxShadow: `0 0 4px ${white}` }}>
        <GotItButton
          onClick={dismiss}
          data-analytics="premium_welcome_modal_acknowledge"
        >
          Got It
        </GotItButton>
      </DialogActions>
    </Dialog>
  )
}

export default PremiumIntroductionDialog
