import { AppBar as MuiAppBar, Link as MuiLink, Box } from '@mui/material'
import styled from 'styled-components'
import moment from 'moment'

import { gray60, white } from '@theme'

const CHEVRON_ICON =
  'https://assets.untappd.com/business/images/chevron-right.svg'
const AppBar = styled(MuiAppBar)`
  && {
    background-color: ${gray60};
    color: ${white};
  }
`

const Link = styled(MuiLink)`
  && {
    color: ${white};
  }
`

export interface ComplimentaryAccessHeaderProps {
  complimentaryAccessEndDate: string | null
}

const ComplimentaryAccessHeader = ({
  complimentaryAccessEndDate,
}: ComplimentaryAccessHeaderProps) => {
  if (!complimentaryAccessEndDate) return <></>

  const daysUntilcompAccessEnd = moment(complimentaryAccessEndDate).diff(
    moment().startOf('day'),
    'days',
  )

  return (
    <AppBar position="static" elevation={0}>
      <Link
        href="https://help.untappd.com/hc/en-us/articles/11293514734868-What-is-Untappd-for-Business-Premium-"
        underline="none"
        target="_blank"
        rel="noreferrer"
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            fontSize: '16',
            height: '40',
            justifyContent: 'center',
            py: 2,
          }}
        >
          <Box sx={{ fontWeight: 'fontWeightBold' }}>
            {daysUntilcompAccessEnd}&nbsp;
          </Box>
          <Box>days left of your Premium Trial.&nbsp;</Box>
          <Box sx={{ fontWeight: 'fontWeightBold' }}>Learn More&nbsp;</Box>
          <Box sx={{ display: 'flex' }}>
            <img alt="chrvron right" src={CHEVRON_ICON} />
          </Box>
        </Box>
      </Link>
    </AppBar>
  )
}

export default ComplimentaryAccessHeader
