import { makeAutoObservable } from 'mobx'

import { QRCode, QRPoster } from '@app/interfaces/contactless-tools'
import RootStore from '@app/stores/RootStore'
import QrCodes from '@app/lib/api/QrCodes'
import QrPosters from '@app/lib/api/QrPosters'

import Location from '../models/Location'

type ContactlessTool = QRCode | QRPoster

const alphabeticalSort = (a: ContactlessTool, b: ContactlessTool): number =>
  a.name.toLowerCase().localeCompare(b.name.toLowerCase())

class ContactlessToolsStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  initialized = false

  qrCodes: QRCode[] = []

  qrPosters: QRPoster[] = []

  initialize = (qrCodes: QRCode[], qrPosters: QRPoster[]): void => {
    this.setQRCodes(qrCodes)
    this.setQRPosters(qrPosters)

    this.initialized = true
  }

  setQRCodes = (qrCodes: QRCode[]): void => {
    this.qrCodes = qrCodes
  }

  setQRPosters = (qrPosters: QRPoster[]): void => {
    this.qrPosters = qrPosters
  }

  addQRCode = (qrCode: QRCode): void => {
    const qrCodes = [...this.qrCodes, qrCode]

    qrCodes.sort(alphabeticalSort)

    this.setQRCodes(qrCodes)
  }

  editQRCode = (updatedQRCode: QRCode): void => {
    const qrCodes = [...this.qrCodes]
    const index = qrCodes.findIndex((code) => code.id === updatedQRCode.id)

    qrCodes[index] = {
      ...updatedQRCode,
      // Visit count is only computed when fetching all qr codes, therefore,
      // it will not be available in the response of the update.
      visitCount: qrCodes[index].visitCount,
    }

    qrCodes.sort(alphabeticalSort)

    this.setQRCodes(qrCodes)
  }

  removeQRCode = (id: number): void => {
    this.setQRCodes(this.qrCodes.filter((qrCode) => qrCode.id !== id))
  }

  removeQRPoster = (id: number): void => {
    this.setQRPosters(this.qrPosters.filter((qrPoster) => qrPoster.id !== id))
  }

  get venue(): Location {
    const venue = this.rootStore.currentSubscription?.venue
    if (!venue) throw new Error('No venue in store')

    return venue
  }

  load = async () => {
    const [qcData, qpData] = await Promise.all([
      QrCodes.list(this.venue.id),
      QrPosters.list(this.venue.id),
    ])

    this.initialize(qcData.data.qrCodes, qpData.data.qrPosters)
  }
}

export default ContactlessToolsStore
