import AnnounceKit from 'announcekit-react'
import { Box } from '@mui/material'

const ANNOUNCEKIT_WIDGET_URL = 'https://announcekit.co/widgets/v2/2jhiX6'

const AnnouncekitWidget = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    fontWeight={600}
  >
    <AnnounceKit widget={ANNOUNCEKIT_WIDGET_URL}>What&apos;s New</AnnounceKit>
  </Box>
)

export default AnnouncekitWidget
