import { observer } from 'mobx-react-lite'
import { Box, Link as MuiLink } from '@mui/material'
import styled from 'styled-components'
import { gray60, useUp } from '@theme'
import { useRootStore } from '@app/stores/hooks'

const Link = styled(MuiLink)`
  && {
    color: ${gray60};
    &:hover {
      color: ${gray60};
    }
  }
`

interface UTFBLogoProps {
  utfbIcon: string
}

const UTFBLogo = ({ utfbIcon }: UTFBLogoProps) => {
  const upLg = useUp('lg')
  const { currentSubscription } = useRootStore()

  // hide logo on small screens if downgrade callout is displayed (not enough space)
  if (!upLg && currentSubscription?.scheduledMigrationDate) return null

  return (
    <Link href="/" color="inherit" underline="none">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        letterSpacing="0.01em"
        fontWeight="fontWeightBold"
        ml={-2}
      >
        <img
          src={utfbIcon}
          alt="Untappd for Business logo"
          style={{ minWidth: 24 }}
        />
        {upLg && <Box>UNTAPPD FOR BUSINESS</Box>}
      </Box>
    </Link>
  )
}

export default observer(UTFBLogo)
