import { ReactNode } from 'react'
import { makeAutoObservable } from 'mobx'
import { AlertProps } from '@mui/material'

import UiStore from '@app/stores/UiStore'

type Severity = AlertProps['severity']

export default class Toaster {
  constructor(store: UiStore) {
    makeAutoObservable(this)

    this.store = store
  }

  open = false

  title: ReactNode = null

  description: ReactNode = null

  severity: Severity = 'info'

  store: UiStore

  autoHideDuration: number

  toast = (
    title: ReactNode,
    severity: Severity,
    description: ReactNode = null,
    autoHideDuration = 5000,
  ): void => {
    this.open = true
    this.title = title
    this.severity = severity
    this.description = description
    this.autoHideDuration = autoHideDuration
  }

  success = (
    title: ReactNode,
    description: ReactNode = null,
    autoHideDuration: number | undefined = undefined,
  ): void => {
    this.toast(title, 'success', description, autoHideDuration)
  }

  info = (
    title: ReactNode,
    description: ReactNode = null,
    autoHideDuration: number | undefined = undefined,
  ): void => {
    this.toast(title, 'info', description, autoHideDuration)
  }

  warning = (
    title: ReactNode,
    description: ReactNode = null,
    autoHideDuration: number | undefined = undefined,
  ): void => {
    this.toast(title, 'warning', description, autoHideDuration)
  }

  error = (
    title: ReactNode,
    description: ReactNode = null,
    autoHideDuration: number | undefined = undefined,
  ): void => {
    this.toast(title, 'error', description, autoHideDuration)
  }

  close = (): void => {
    this.open = false
  }

  reset = (): void => {
    this.title = null
    this.severity = undefined
    this.description = null
  }
}
