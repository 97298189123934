import { makeAutoObservable } from 'mobx'

import UiStore from '@app/stores/UiStore'

interface StateI {
  [index: string]: boolean
}

export default class Sidebar {
  store: UiStore

  initialState: StateI = {
    analytics: false,
    announcements: false,
    customize: false,
    settings: false,
  }

  state: StateI = {}

  constructor(store: UiStore) {
    this.store = store
    this.loadState()

    makeAutoObservable(this, { store: false })
  }

  loadState = (): void => {
    this.state = this.initialState

    const loadedState = localStorage.getItem('sidebarState')
    if (loadedState) {
      this.state = JSON.parse(loadedState)
      return
    }

    this.saveState()
  }

  saveState = (): void => {
    localStorage.setItem('sidebarState', JSON.stringify(this.state))
  }

  update = (key: string): void => {
    this.state = {
      ...this.state,
      [key]: !this.state[key],
    }
    this.saveState()
  }
}
