import Base from './Base'

class QrPosters extends Base {
  static async list(locationId: number) {
    return this.get(`locations/${locationId}/qr_posters`)
  }

  static async destroy(id: number) {
    return this.delete(`/qr_posters/${id}`)
  }
}

export default QrPosters
