import { useContext } from 'react'

import { StoreContext } from '@app/contexts/StoreContext'

import RootStore from './RootStore'
import UiStore from './UiStore'
import ContactlessToolsStore from './ContactlessToolsStore'
import CheckoutStore from './CheckoutStore'
import DialogStore from './DialogStore'
import MenuBuilderStore from './MenuBuilderStore'
import FavoriteContainersStore from './FavoriteContainersStore'
import Menu from './MenuBuilderStore/Menu'
import Section from './MenuBuilderStore/Section'
import Item from './MenuBuilderStore/Item'
import LocalBadgeStore from './InsightsStore/LocalBadgeStore'

// Importing this here for now so we don't have to change all instances of `useBoard`.
import {
  useBoard,
  useDigitalBoardStore,
} from '@app/pages/DigitalBoards/hooks/store'
export { useBoard, useDigitalBoardStore }

export const useRootStore = (): RootStore => useContext(StoreContext)
export const useCurrentUser = () => {
  const { currentUser } = useRootStore()
  if (!currentUser) throw new Error('No current user in store')
  return currentUser
}
export const useCurrentSubscription = () => {
  const { currentSubscription } = useRootStore()
  if (!currentSubscription) throw new Error('No current subscription in store')
  return currentSubscription
}
export const useCurrentLocation = () => {
  const { venue } = useCurrentSubscription()
  if (!venue) throw new Error('No current location in store')
  return venue
}

export const useUiStore = (): UiStore => useRootStore().uiStore
export const useContactlessToolsStore = (): ContactlessToolsStore =>
  useRootStore().contactlessToolsStore
export const useCheckoutStore = (): CheckoutStore =>
  useRootStore().checkoutStore
export const useDialogStore = (): DialogStore => useRootStore().dialogStore
export const useMenuBuilderStore = (): MenuBuilderStore =>
  useRootStore().menuBuilderStore
export const useFavoriteContainersStore = (): FavoriteContainersStore =>
  useMenuBuilderStore().favoriteContainersStore

export const useLocalBadgeStore = (): LocalBadgeStore =>
  useRootStore().localBadgeStore

export const useMenu = (): Menu => {
  const { menu } = useMenuBuilderStore()
  if (!menu) throw new Error('No menu in store')
  return menu
}

export const useSection = (): Section => {
  const section = useMenu().currentSection
  if (!section) throw new Error('No section in store')
  return section
}

export const useItem = (): Item => {
  const item = useSection().currentItem
  if (!item) throw new Error('No item in store')
  return item
}
