import { Shadows } from '@mui/material'

const shadows: Shadows = [
  'none',
  `0 0 4px rgb(0, 0, 0, 0.25)`,
  `0 0 8px rgb(0, 0, 0, 0.25)`,
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
]

export default shadows
