import { makeAutoObservable } from 'mobx'
import RootStore from '@app/stores/RootStore'

export default class DialogStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  currentDialog: string | null = null

  open = (dialog: string): void => {
    this.currentDialog = dialog
  }

  close = (): void => {
    this.currentDialog = null
  }
}
