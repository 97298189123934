import { Dialog as MuiDialog } from '@mui/material'

import styled from 'styled-components'
import { white } from '@theme'

const DialogDarkBackground = styled(MuiDialog)`
  .MuiDialog-paper {
    background-image: url('https://assets.untappd.com/business/images/premium_tier_modal_background.png');
    color: ${white};
  }

  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    min-width: 600px;
  }
`

export default DialogDarkBackground
