import { makeAutoObservable } from 'mobx'

import Items from '@app/lib/api/Items'

import Menu from './Menu'
import Section from './Section'
import Item from './Item'

export default class MoveItems {
  fromSection: Section

  items: Item[] = []

  toMenu: Menu | null = null

  toSection: Section | null = null

  replaceItemId: number | null = null

  showError = false

  constructor(fromSection: Section) {
    this.fromSection = fromSection

    makeAutoObservable(this, { fromSection: false })
  }

  setItems = (items: Item[]) => {
    this.items = items
  }

  setToMenu = (menu: Menu | null) => {
    this.toMenu = menu
  }

  setToSection = (section: Section | null) => {
    this.toSection = section
  }

  setReplaceItemId = (replaceItemId: number | null) => {
    this.replaceItemId = replaceItemId
  }

  setShowError = (showError: boolean) => {
    this.showError = showError
  }

  run = async () => {
    if (!this.toSection) return
    if (this.replaceItemId) return this.replace()

    const itemIds = new Set(this.items.map((item) => item.id))
    const { data } = await Items.bulkMove(this.toSection.id, {
      originalSectionId: this.fromSection.id,
      itemIds: [...itemIds],
      all: this.fromSection.allSelected,
    })

    // Given that toSection is pulled off of the menus list, it isn't the same
    // instance of Section as the one in the current menu.
    if (this.toSection.menu.id === this.fromSection.menu.id) {
      const toSection = this.fromSection.menu.genericSections.find(
        (section) => section.id === this.toSection?.id,
      )!

      toSection.setItemsCount(toSection.itemsCount + data.items.length)
    }

    const {
      setItemsCount,
      setItems,
      itemsCount,
      items,
      unselectAllItems,
      loadItems,
    } = this.fromSection

    setItemsCount(itemsCount - data.items.length)
    setItems(items.filter((item) => !itemIds.has(item.id)))
    unselectAllItems()
    if (items.length === 0 && itemsCount > 0) {
      loadItems()
    }
  }

  replace = async () => {
    if (this.items.length !== 1 || !this.replaceItemId) return
    const itemId = this.items[0].id

    await Items.replace(itemId, this.replaceItemId)

    const items = this.fromSection.items.filter((item) => item.id !== itemId)
    this.fromSection.setItems(items)
    this.fromSection.setItemsCount(this.fromSection.itemsCount - 1)
  }

  get menus() {
    return this.fromSection.menu.menuBuilderStore.menus.filter((menu) => {
      const sections = menu.genericSections.filter(
        (section) => section.id !== this.fromSection.id,
      )

      return sections.length > 0
    })
  }

  get sections() {
    if (!this.toMenu) return []

    return this.toMenu.genericSections.filter(
      (section) => section.id !== this.fromSection.id,
    )
  }

  reset = () => {
    this.setItems([])
    this.setToMenu(null)
    this.setToSection(null)
    this.setReplaceItemId(null)
  }
}
