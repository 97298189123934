import { IMenu, Position } from '@app/interfaces/shared'
import Base from './Base'

export interface CreateInput {
  name: string
  description: string
  showPriceOnUntappd: boolean
}

export interface DuplicateInput {
  menus: Partial<IMenu>[]
  locationIds: number[]
}

export interface UpdateParams {
  name?: string
  description?: string
  showPriceOnUntappd?: boolean
  unpublished?: boolean
}

class Menus extends Base {
  static async list(locationId: number | null = null) {
    if (locationId === null) return this.get('/menus')

    return this.get(`/locations/${locationId}/menus`)
  }

  static async retrieve(id: number) {
    return this.get(`/menus/${id}`)
  }

  static async create(
    locationId: number,
    { name, description, showPriceOnUntappd }: CreateInput,
  ) {
    return this.post(`/locations/${locationId}/menus`, {
      name,
      description,
      show_price_on_untappd: showPriceOnUntappd,
    })
  }

  static async publish(menuIds: number[]) {
    return this.post('/menus/publish', {
      menu_ids: menuIds,
    })
  }

  static async unpublish(menuIds: number[]) {
    return this.post('/menus/unpublish', {
      menu_ids: menuIds,
    })
  }

  static async duplicate({ menus, locationIds }: DuplicateInput) {
    return this.post('/menus/duplicate', {
      menus,
      location_ids: locationIds,
    })
  }

  static async reorder(locationId: number, positions: Position[]) {
    return this.patch(`/locations/${locationId}/menus/positions`, {
      menus: positions,
    })
  }

  static async bulkDestroy(menuIds: number[]) {
    return this.delete('/internal/bulk_menus', {
      data: {
        menu_ids: menuIds,
      },
    })
  }

  static async update(id: number, params: UpdateParams) {
    return this.put(`/menus/${id}`, params)
  }

  static async destroy(id: number) {
    return this.delete(`/menus/${id}`)
  }
}

export default Menus
