import qs from 'qs'

import Base from './Base'

import {
  QrCodeCreateValues,
  QrCodeUpdateValues,
  QrCodesCheckNameValues,
} from '@app/interfaces/contactless-tools'

class QrCodes extends Base {
  static async list(locationId: number) {
    return this.get(`locations/${locationId}/qr_codes`)
  }

  static async create({ locationId, ...params }: QrCodeCreateValues) {
    return this.post(`/locations/${locationId}/qr_codes`, params)
  }

  static async update({ qrCodeId, ...params }: QrCodeUpdateValues) {
    return this.patch(`/qr_codes/${qrCodeId}`, params)
  }

  static async destroy(id: number) {
    return this.delete(`/qr_codes/${id}`)
  }

  static async checkName({ locationId, ...params }: QrCodesCheckNameValues) {
    return this.get(
      `locations/${locationId}/qr_codes/check_name?${qs.stringify(params)}`,
    )
  }
}

export default QrCodes
