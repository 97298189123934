import { Box } from '@mui/material'
import styled from 'styled-components'

const Truncated = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default Truncated
