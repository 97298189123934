import { makeAutoObservable } from 'mobx'

import RootStore from '@app/stores/RootStore'
import Menus from '@app/lib/api/Menus'
import Containers from '@app/lib/api/Containers'
import { IMenu, SelectOption } from '@app/interfaces/shared'
import UntappdBeerStyles from '@app/lib/api/UntappdBeerStyles'

import Menu from './Menu'
import FavoriteContainersStore from '../FavoriteContainersStore'
import { AxiosError } from 'axios'

export default class MenuBuilderStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.favoriteContainersStore = new FavoriteContainersStore(this)
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  isLoading = true

  filter = ''

  selectedMenus = new Set<number>()

  menus: Menu[] = []

  menu: Menu | null = null

  containerSizeOptions: SelectOption[] = []

  untappdBeerStyles: SelectOption[] = []

  showAddMenu = false

  showFavoriteContainers = false

  favoriteContainersStore: FavoriteContainersStore

  loadMenu = async (id: number) => {
    this.setIsLoading(true)
    try {
      const { data } = await Menus.retrieve(id)
      const menu = new Menu(this, data.menu)
      menu.loadSections()
      this.setMenu(menu)
    } catch (e) {
      this.rootStore.handleAxiosError(e as AxiosError)
    } finally {
      this.setIsLoading(false)
    }
  }

  loadMenus = async () => {
    try {
      const { data } = await Menus.list(this.venue.id)

      this.setMenus(data.menus)
      this.menus.forEach((menu) => menu.loadSections())
    } catch (e) {
      this.rootStore.handleAxiosError(e as AxiosError)
    }
  }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  setMenu = (menu: Menu | null) => {
    this.menu = menu
  }

  setMenus = (menus: IMenu[]) => {
    this.menus = menus.map((menu) => new Menu(this, menu))
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setShowAddMenu = (showAddMenu: boolean) => {
    this.showAddMenu = showAddMenu
  }

  setShowFavoriteContainers = (showFavoriteContainers: boolean) => {
    this.showFavoriteContainers = showFavoriteContainers
  }

  deleteMenu = async () => {
    await Menus.destroy(this.menu!.id)
    this.setMenu(null)
  }

  loadContainerSizeOptions = async () => {
    const {
      data: { containerSizeOptions },
    } = await Containers.containerSizeOptions()
    this.setContainerSizeOptions(
      containerSizeOptions.map((size: SelectOption) => ({
        label: size.label,
        value: size.value,
      })),
    )
  }

  setContainerSizeOptions = (containerSizeOptions: SelectOption[]) => {
    this.containerSizeOptions = containerSizeOptions
  }

  loadUntappdBeerStyles = async () => {
    try {
      const { data } = await UntappdBeerStyles.list(this.venue.id)

      const untappdBeerStyles = data.untappdBeerStyles.map(
        (style: { typeName: string; typeId: number }) => ({
          label: style.typeName,
          value: style.typeId,
        }),
      )

      this.setUntappdBeerStyles(untappdBeerStyles)
    } catch {
      // noop
    }
  }

  setUntappdBeerStyles = (styles: SelectOption[]) => {
    this.untappdBeerStyles = styles
  }

  selectAll = () => {
    this.selectedMenus = new Set(this.filteredMenus.map((menu) => menu.id))
  }

  clearSelections = () => {
    this.selectedMenus.clear()
  }

  get filteredMenus() {
    return this.menus.filter(
      (menu) =>
        menu.name.toLowerCase().includes(this.filter.toLowerCase()) ||
        (this.locationById[menu.locationId] || '')
          .toLowerCase()
          .includes(this.filter.toLowerCase()),
    )
  }

  get allSelected() {
    return (
      this.selectedMenus.size > 0 &&
      this.selectedMenus.size === this.filteredMenus.length
    )
  }

  get locationById() {
    return this.rootStore.subscriptions.reduce(
      (acc: Record<number, string>, subscription) => {
        acc[subscription.venue.id] = subscription.venue.name
        return acc
      },
      {},
    )
  }

  get venue() {
    const venue = this.rootStore.currentSubscription?.venue
    if (!venue) throw new Error('No venue found')
    return venue
  }
}
