import { Position } from '@app/interfaces/shared'

import Base from './Base'

export interface Params {
  name: string
  description: string
  public: boolean
}

class Sections extends Base {
  static async list(menuId: number) {
    return this.get(`/menus/${menuId}/sections?include_on_deck_section=true`)
  }

  static async create(menuId: number, params: Params) {
    return this.post(`/menus/${menuId}/sections`, params)
  }

  static async update(id: number, params: Params) {
    return this.put(`/sections/${id}`, params)
  }

  static async updatePositions(menuId: number, positions: Position[]) {
    return this.patch(`/menus/${menuId}/sections/positions`, {
      sections: positions,
    })
  }

  static async destroy(id: number) {
    return this.delete(`/sections/${id}`)
  }
}

export default Sections
