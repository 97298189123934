import { makeAutoObservable } from 'mobx'

import { IContainer } from '@app/interfaces/shared'
import Containers, { ContainerDataParams } from '@app/lib/api/Containers'

import Item from './Item'

export default class Container implements IContainer {
  id: number | string | null = null
  item?: Item
  containerSizeId: number | string = ''
  price: number | null = null
  calories: number | null = null
  position: number | null = null
  name: string | null = null
  currency: string | null = null
  currencySymbol: string | null = null
  key: string

  constructor(item: Item, container: ContainerDataParams = {}) {
    this.item = item
    this.set(container)
    this.key = Math.random().toString(36).substring(7)

    makeAutoObservable(this, { item: false })
  }

  set = (container: IContainer | ContainerDataParams) => {
    Object.assign(this, container)
  }

  update = (params: ContainerDataParams) => {
    Object.assign(this, params)
  }

  save = async () => {
    if (this.id === null)
      throw new Error('Cannot save a container without an id')

    const { data } = await Containers.update(Number(this.id), this.attributes)
    const { container } = data
    this.set(container)
  }

  setCalories = (calories: number) => {
    this.calories = calories
  }

  setPrice = (price: number) => {
    this.price = price
  }

  setId = (id: number) => {
    this.id = id
  }

  setPosition = (position: number) => {
    this.position = position
  }

  setContainerSizeId = (containerSizeId: number) => {
    this.containerSizeId = containerSizeId
  }

  setName = (name: string) => {
    this.name = name
  }

  get formatedPrice() {
    if (this.price === null) return null

    return `${this.currencySymbol}${this.price} ${this.currency}`
  }

  get attributes(): ContainerDataParams {
    return {
      containerSizeId: this.containerSizeId,
      calories: this.calories,
      price: this.price,
      name: this.name,
    }
  }
}
