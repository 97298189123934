import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

const ButtonProgress = styled(CircularProgress).attrs({
  color: 'inherit',
  size: 24,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
`

export default ButtonProgress
