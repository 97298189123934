import { useState } from 'react'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import { Box, Link as MuiLink, Button } from '@mui/material'
import { COOKIE_NAME } from './'

const cookies = new Cookies()

const Container = styled(Box).attrs({
  px: 5,
  py: 4,
})`
  background-color: #001624;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 1002;
  color: white;
  display: flex;
  font-weight: 300;
`

const Link = styled(MuiLink).attrs({
  color: 'inherit',
  underline: 'always',
  target: '_blank',
  rel: 'noopener',
})`
  font-weight: 300;
`

const AgreementBtn = styled(Button).attrs({
  variant: 'text',
  color: 'inherit',
})`
  font-weight: 800;
  padding: 0;
  border: none;

  &:hover,
  &:active {
    background-color: transparent;
  }
`

const domainName = () => {
  const { hostname } = window.location
  if (hostname === 'localhost') return 'localhost'

  return `.${hostname}`
}

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(cookies.get(COOKIE_NAME) === 'true')

  const acceptTerms = () => {
    cookies.set(COOKIE_NAME, 'true', {
      domain: domainName(),
      maxAge: 31536000,
      path: '/',
    })
    setAccepted(true)
  }

  if (accepted) return null

  return (
    <Container>
      <Box display="flex" alignItems="center">
        <Box mr={5}>
          Untappd for Business uses cookies. By continuing to use Untappd for
          Business, you agree to our{' '}
          <Link href="https://untappd.com/cookie">Cookie Policy</Link>.
        </Box>
        <AgreementBtn onClick={acceptTerms}>I AGREE</AgreementBtn>
      </Box>
    </Container>
  )
}

export default CookieBanner
