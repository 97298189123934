import Base from './Base'

class LocalBadges extends Base {
  static async list(params: { venueId: number | undefined }) {
    const { data } = await this.get(`/locations/${params.venueId}/local_badges`)

    return data
  }
}

export default LocalBadges
