import { makeAutoObservable } from 'mobx'
import { arrayMove } from '@dnd-kit/sortable'
import { IFavoriteContainer, IItem } from '@app/interfaces/shared'
import Items, { UpdateParams } from '@app/lib/api/Items'
import Containers, { ContainerDataParams } from '@app/lib/api/Containers'
import RootStore from '@app/stores/RootStore'
import { BEER_ITEM_TYPE } from '@app/lib/constants'

import Section from './Section'
import Container from './Container'
import Subscription from '../models/Subscription'

export default class Item implements IItem {
  section: Section
  id: number
  name: string
  brewery?: string | null
  producer?: string | null
  style: string | null
  category?: string | null
  vintage?: string | null
  characteristics?: string | null
  location?: string | null
  abv?: number | string | null
  ibu?: number | string | null
  calories?: string | null
  labelImage: string
  labelImageThumb: string
  type: string
  description?: string
  rating?: number
  tapNumber?: string
  inProduction: boolean
  sourceIdentifier?: string
  itemSourceId?: number
  nitro: boolean
  cask: boolean
  local: boolean
  customLabelImage?: string | null
  customLabelImageThumb?: string | null
  customLabelImageFilename?: string | null
  updatedAt: string
  sectionId: number
  untappdId?: number
  untappdBreweryId?: number
  containers: Container[] = []
  newUnsavedContainer: Container = new Container(this)
  showFirstContainerIcon = false
  showContainers = false
  createdAt: string
  position: number
  isLoading = false

  constructor(section: Section, item: IItem) {
    this.section = section
    this.set({
      ...item,
      containers: (item.containers || []).map(
        (container) => new Container(this, container),
      ),
    })

    makeAutoObservable(this, { section: false })
  }

  set = (item: IItem) => {
    Object.assign(this, item)
  }

  update = (params: UpdateParams) => {
    Object.assign(this, params)
  }

  resetField = async (field: string) => {
    const { data } = await Items.update(this.id, {
      [field]: null,
    })

    this.set(data.item)
  }

  uploadImage = async (file: File | null) => {
    if (file) {
      const tempUrl = URL.createObjectURL(file)
      this.labelImageThumb = tempUrl
      this.customLabelImageThumb = tempUrl
      this.customLabelImageFilename = file.name
    } else {
      this.customLabelImageThumb = null
      this.customLabelImageFilename = null
    }

    const { data } = await Items.uploadImage(this.id, file)
    this.set(data.item)
  }

  setContainers = async (containers: Container[]) => {
    this.containers = containers
  }

  createContainer = async () => {
    this.setIsLoading(true)
    const newContainer = new Container(
      this,
      this.newUnsavedContainer.attributes,
    )
    newContainer.setPosition(this.containers.length)
    this.containers.push(newContainer)
    this.setNewUnsavedContainer()
    const { data } = await Containers.create(this.id, newContainer.attributes)
    const { container } = data
    newContainer.set(container)
    this.setIsLoading(false)
  }

  bulkCreateContainers = async (
    containers: Container[] | IFavoriteContainer[],
  ) => {
    this.setIsLoading(true)
    const { data } = await Containers.bulkCreate(this.section.id, {
      containers,
      itemIds: [this.id],
      all: false,
    })

    this.setContainers([
      ...this.containers,
      ...data.containers.map(
        (container: ContainerDataParams | undefined) =>
          new Container(this, container),
      ),
    ])
    this.setIsLoading(false)
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setNewUnsavedContainer = () => {
    this.newUnsavedContainer = new Container(this)
  }

  setShowContainers = (show: boolean) => {
    this.showContainers = show
  }

  deleteContainer = async (id: number | null) => {
    this.setContainers(this.containers.filter((c) => c.id !== id))
    if (id !== null) Containers.destroy(id)
  }

  updateContainerPosition = async (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return

    const containers = arrayMove(this.containers, oldIndex, newIndex)
    this.setContainers(containers)
    await Containers.updatePositions(
      this.id,
      this.containers.map((c, idx) => ({ id: c.id!, position: idx })),
    )
  }

  save = async () => {
    if (this.abv === '') this.resetField('abv')
    if (this.ibu === '') this.resetField('ibu')
    const { data } = await Items.update(this.id, this.attributes)

    this.updatedAt = data.item.updatedAt
  }

  setPosition = (position: number) => {
    this.position = position
  }

  get resettable(): boolean {
    return (
      (this.subscription.isPremium && Boolean(this.itemSourceId)) || this.isBeer
    )
  }

  get editDisabled(): boolean {
    const {
      isPremium,
      permissions,
      venue: { genericItemsCount },
      entitlements: { maxGenericItems },
    } = this.subscription

    return (
      (!isPremium &&
        !this.isBeer &&
        maxGenericItems > 0 &&
        genericItemsCount > maxGenericItems) ||
      !permissions.canEditBeerInfo
    )
  }

  get allContainersSaved(): boolean {
    return this.containers.every((c) => c.id !== null)
  }

  get isBeer(): boolean {
    return this.type === BEER_ITEM_TYPE
  }

  get subscription(): Subscription {
    return this.rootStore.currentSubscription!
  }

  get rootStore(): RootStore {
    return this.section.menu.menuBuilderStore.rootStore
  }

  get attributes(): UpdateParams {
    return {
      name: this.name,
      brewery: this.brewery,
      producer: this.producer,
      style: this.style,
      category: this.category,
      vintage: this.vintage,
      characteristics: this.characteristics,
      location: this.location,
      abv: this.abv,
      ibu: this.ibu,
      calories: this.calories,
      description: this.description,
      tapNumber: this.tapNumber,
      nitro: this.nitro,
      cask: this.cask,
      local: this.local,
    }
  }
}
